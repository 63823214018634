import { isPast } from 'date-fns';

import { normalizeDate } from '@ha/date';

import { OCCUPATION } from 'ha/constants/Occupation';

import { Gender } from 'ha/types/Gender';
import { GenderValue, SuitableForValue } from 'ha/types/SearchFilters';
import { getFromStorage, setInStorage } from 'ha/utils/storage';

import { MATCHMAKING_FILTERS_STORAGE_KEY } from './constants';
import {
  MatchmakingFilterProperties,
  SavedMatchmakingProperties,
} from './types';

export const occupationToFilterValues = (
  occupation: OCCUPATION | SuitableForValue,
): MatchmakingFilterProperties['suitableFor'] => {
  switch (occupation) {
    case OCCUPATION.workingProfessional:
    case SuitableForValue.WORKING_PROFESSIONALS:
      return [SuitableForValue.WORKING_PROFESSIONALS];
    case OCCUPATION.student:
    case SuitableForValue.STUDENTS:
      return [SuitableForValue.STUDENTS];
    default:
      return [];
  }
};

export const genderToFilterValues = (
  gender: Gender,
): MatchmakingFilterProperties['gender'] => {
  switch (gender) {
    case Gender.male:
      return [GenderValue.BOYS];
    case Gender.female:
      return [GenderValue.GIRLS];
    default:
      return [];
  }
};

export const getValidMoveInDate = (moveInDate?: string) => {
  if (!moveInDate) return null;
  const date = new Date(moveInDate);
  return isPast(date) ? normalizeDate(new Date().toISOString()) : moveInDate;
};

export const storageLoadMatchmakingProperties = () => {
  return getFromStorage<SavedMatchmakingProperties>(
    MATCHMAKING_FILTERS_STORAGE_KEY,
  );
};

export const storageUpdateMatchmakingProperties = (
  properties?: Partial<SavedMatchmakingProperties>,
) => {
  const storedFilters = storageLoadMatchmakingProperties();

  setInStorage(MATCHMAKING_FILTERS_STORAGE_KEY, {
    ...storedFilters,
    ...properties,
  });
};
